/* top menu */

.ast-header-button-1[data-section*="section-hb-button-"] .ast-builder-button-wrap .ast-custom-button {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
}

.ast-custom-button {
    color: #000000;
    background: #ffffff;
    border-color: #000000;
    border: 1px solid #000000;
    border-radius: 0;
}

.ast-custom-button:focus,
.ast-custom-button:active,
.ast-custom-button:hover {
    color: #FFFFFF;
    background-color: #000000;
}


/*  End Top Menu */


/* body  */

.topTextContent {
    margin-top: 4rem;
    text-align: center;
    color: #4B4C59;
    font-family: "Inter", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.5px;
}

.productText {
    margin-top: 2.5rem;
}

.cardContiner {
    margin-top: 5%;
}

body {
    background-color: #F2F2F2 !important;
}

.img-logo {
    height: 60px;
    width: 100%;
}

.img-name {
    height: 38px;
}

.img-name130 {
    height: 38px;
    object-fit: contain;
    width: 12rem;
}

.card {
    margin: 10px 10px 10px 10px;
    background-color: #FFFFFF;
    border: none;
    border-radius: 0;
}

.card-body {
    padding: 3rem 1rem !important;
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    padding: 10px;
    align-content: space-between;
}

.content {
    margin-top: 1rem;
    text-align: center;
    color: #4B4C59;
    font-family: "Inter", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.5px;
}

.common-button-black {
    fill: #000000;
    color: #000000;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #000000;
    padding: 10px 40px 10px 40px;
    border-radius: 0px 0px 0px 0px;
    transition: all .3s;
    font-size: 15px;
}

.common-button-blue {
    fill: #4931FE;
    color: #4931FE;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #4931FE;
    padding: 10px 40px 10px 40px;
    border-radius: 0px 0px 0px 0px;
    transition: all .3s;
    font-size: 15px;
}

.common-button-green {
    fill: #00A59B;
    color: #00A59B;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #00A59B;
    padding: 10px 40px 10px 40px;
    border-radius: 0px 0px 0px 0px;
    transition: all .3s;
    font-size: 15px;
}

.common-button-yellow {
    fill: #D29932;
    color: #D29932;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #D29932;
    padding: 10px 40px 10px 40px;
    border-radius: 0px 0px 0px 0px;
    transition: all .3s;
    font-size: 15px; 
}

.common-button-maroon {
    fill: #984251;
    color: #984251;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #984251;
    padding: 10px 40px 10px 40px;
    border-radius: 0px 0px 0px 0px;
    transition: all .3s;
    font-size: 15px; 
}

.common-button-maroon:focus,
.common-button-maroon:active,
.common-button-maroon:hover {
    color: #FFFFFF;
    background-color: #984251;
}

.common-button-black:focus,
.common-button-black:active,
.common-button-black:hover {
    color: #FFFFFF;
    background-color: #000000;
}

.common-button-blue:focus,
.common-button-blue:active,
.common-button-blue:hover {
    color: #FFFFFF;
    background-color: #4931FE;
}

.common-button-green:focus,
.common-button-green:active,
.common-button-green:hover {
    color: #FFFFFF;
    background-color: #00A59B;
}

.ast-container,
.ast-container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width:992px) {
    .ast-width-sm-25 {
        width: 25%
    }
    .ast-width-md-50 {
        width: 50%
    }
}

@media (max-width:768px) {
    .ast-width-md-25 {
        width: 25%
    }
}

.ast-full-width {
    width: 100%
}

.ast-width-50 {
    width: 50%
}

@media (min-width:768px) {
    .ast-width-md-4 {
        width: 33.3333333333%
    }
    .ast-width-md-16 {
        width: 16.6666666667%
    }
    .ast-width-md-6 {
        width: 50%;
        float: left
    }
    .ast-width-md-3 {
        width: 25%
    }
    .ast-width-md-66 {
        width: 66.6666666667%
    }
}

@media (min-width:769px) {
    .ast-width-md-20 {
        width: 20%
    }
}

@media (min-width:992px) {
    .ast-width-lg-33 {
        width: 33.3333333333%
    }
    .ast-width-lg-16 {
        width: 16.6666666667%
    }
    .ast-width-lg-50 {
        width: 50%
    }
    .ast-width-lg-66 {
        width: 66.6666666667%
    }
}

.ast-builder-grid-row {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-column-gap: 20px;
    overflow-wrap: anywhere
}

.ast-grid-right-section {
    justify-content: flex-end
}

.main-header-bar {
    background-color: #fff;
    border-bottom-color: #eaeaea;
    border-bottom-style: solid
}

.ast-header-break-point .main-header-bar {
    border: 0
}

.main-header-bar {
    z-index: 4;
    position: relative
}

.custom-mobile-logo-link {
    display: none
}

.ast-site-identity {
    padding: 1em 0
}

.ast-header-break-point .site-header .main-header-bar-wrap .site-branding {
    flex: 1;
    align-self: center
}

.ast-flex {
    -js-display: flex;
    display: flex;
    flex-wrap: wrap
}

.ast-flex-1 {
    flex: 1
}

.ast-inline-flex {
    -js-display: inline-flex;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center
}

@media (min-width: 922px) {
    .ast-container {
        max-width: 1340px;
    }
    .ast-primary-header-bar {
        border-bottom-width: 0;
        border-bottom-color: #eaeaea;
        border-bottom-style: solid;
    }
    .main-header-bar {
        border-bottom-width: 1px;
    }
}


/* ======================================= */

.heading {
    color: #000000;
    font-family: "Inter", Sans-serif;
    font-size: 28px;
    font-weight: 400;
}

.ast-custom-button-link {
    text-decoration: none;
}